<template>
  <div class="l-page">
    <header class="header">
      <div class="container">
        <div class="header__inner">
          <a
            href="#"
            class="header__logo">
            <img
              src="@/assets/images/new_logo_white.svg"
              alt="" />
          </a>
          <button
            class="header__burger burger"
            :class="{ active: showMenu }"
            @click="handleBurger">
            <div class="burger__line"></div>
          </button>
          <nav
            class="header__nav"
            :class="{ active: showMenu }">
            <a
              class="header__nav-item"
              href="#about"
              >Об&nbsp;олимпиаде</a
            >
            <a
              class="header__nav-item"
              href="#participants"
              >Участникам</a
            >
            <!-- <a class="header__nav-item" href="#steps">Этапы</a> -->
            <a
              class="header__nav-item"
              href="#preparation"
              >Подготовка</a
            >
            <a
              class="header__nav-item"
              href="#to-mentors-and-teachers"
              >Наставникам и учителям</a
            >
          </nav>
          <BviButton
            theme="white"
            class="special-button" />
        </div>
      </div>
    </header>

    <main id="main-content">
      <Hero
        :start-date="startDate"
        :reg-url="regLink"
        :pre-reg-url="preRegUrl"
        :is-all-reg-closed="isAllRegClosed"
        :start-duration="startDuration"
        :loading="loading"
        :login-url="loginUrl"></Hero>

      <section class="section">
        <Swiper
          class="features-slider"
          :options="swiperOptions">
          <SwiperSlide class="features-slider__slide">
            <div class="features-slider__slide-img">
              <img
                src="@/assets/images/landing/slider/slide1.jpg"
                width="376"
                height="220"
                alt="" />
            </div>
            <p>Технологии</p>
          </SwiperSlide>
          <SwiperSlide class="features-slider__slide">
            <div class="features-slider__slide-img">
              <img
                src="@/assets/images/landing/slider/slide2.jpg"
                width="376"
                height="220"
                alt="" />
            </div>
            <p>Ведущие инженерные вузы</p>
          </SwiperSlide>
          <SwiperSlide class="features-slider__slide">
            <div class="features-slider__slide-img">
              <img
                src="@/assets/images/landing/slider/slide3.jpg"
                width="376"
                height="220"
                alt="" />
            </div>
            <p>Единомышленники</p>
          </SwiperSlide>
          <SwiperSlide class="features-slider__slide">
            <div class="features-slider__slide-img">
              <img
                src="@/assets/images/landing/slider/slide4.jpg"
                width="376"
                height="220"
                alt="" />
            </div>
            <p>Реальные задания</p>
          </SwiperSlide>
          <SwiperSlide class="features-slider__slide">
            <div class="features-slider__slide-img">
              <img
                src="@/assets/images/landing/slider/slide5.jpg"
                width="376"
                height="220"
                alt="" />
            </div>
            <p>Работа в команде</p>
          </SwiperSlide>
          <SwiperSlide class="features-slider__slide">
            <div class="features-slider__slide-img">
              <img
                src="@/assets/images/landing/slider/slide6.jpg"
                width="376"
                height="220"
                alt="" />
            </div>
            <p>Научные лаборатории</p>
          </SwiperSlide>
        </Swiper>
      </section>

      <section
        id="about"
        class="section about">
        <div class="container">
          <h2 class="h2">Об&nbsp;олимпиаде</h2>
          <p class="about__subtitle">
            Национальная технологическая олимпиада&nbsp;&mdash; это командные
            инженерные соревнования для школьников и&nbsp;студентов, увлеченных
            современными технологиями и&nbsp;естественными науками. Здесь решают
            самые актуальные задачи, находят друзей и&nbsp;единомышленников,
            поступают в&nbsp;лучшие вузы и&nbsp;становятся востребованными
            специалистами.
          </p>
          <div class="about__row">
            <div class="about__count">
              <p class="about__count-number">30</p>
              <div class="about__count-icon">
                <img
                  src="@/assets/images/landing/about/icon_plus.svg"
                  alt="" />
                <span>направлений</span>
              </div>
            </div>
            <!-- <div class="about__icons">
              <img src="@/assets/images/landing/about/about_icons.svg" alt="" />
            </div> -->
          </div>
          <div class="about__panel">
            <p>Информация обо всех треках НТО</p>
            <BaseButton
              theme="reg"
              size="lg"
              tag="a"
              href="https://ntcontest.ru/tracks"
              target="_blank"
              rel="noopener noreferrer"
              >Подробнее о треках</BaseButton
            >
          </div>
        </div>
      </section>

      <section
        id="past-olympiad"
        class="past-olympiad">
        <div
          class="video-poster"
          @click.prevent="showModal"></div>
        <!-- <a
          class="past-olympiad__btn"
          href="#"
          @click.prevent="showModal"
          >Посмотри, как проходит олимпиада</a
        > -->
      </section>

      <div class="container">
        <section class="section features">
          <div class="row">
            <div class="col-md-6 col-xs-12 mb-xl">
              <h3 class="h3">Цель НТО</h3>
              <p>
                Мы&nbsp;хотим, чтобы каждый школьник и&nbsp;студент смог
                осознанно выбрать и&nbsp;освоить профессию мечты, связанную
                с&nbsp;современными технологиями.
              </p>
              <p>
                Мы&nbsp;создали новый вид социального института, объединивший
                молодых людей, ученых, экспертов, представителей государства
                и&nbsp;бизнеса, где каждый получает возможность реализовать себя
                и&nbsp;поддержку на&nbsp;этом пути.
              </p>
            </div>
            <div class="col-md-6 col-xs-12">
              <h3 class="h3">Зачем участвовать именно тебе?</h3>

              <ul>
                <li>&mdash;&nbsp;Льготы при поступлении в&nbsp;вузы.</li>
                <li>
                  &mdash;&nbsp;Приглашения на&nbsp;стажировки в&nbsp;компаниях
                  и&nbsp;лабораториях.
                </li>
                <li>&mdash;&nbsp;Призы и&nbsp;дипломы за&nbsp;достижения.</li>
                <li>&mdash;&nbsp;Новые знакомства, новые связи, новый опыт.</li>
              </ul>
            </div>
          </div>
          <!-- <div class="features__row">
            <div class="features__item">
              <div class="features__item-stage">
                <img
                  class="features__item-img"
                  src="@/assets/images/landing/features/win.png"
                  alt=""
                />
              </div>
              <div class="features__item-content">
                <h3 class="h3">
                  Победа в&nbsp;олимпиаде дает преимущества для поступления
                </h3>
                <p>
                  45% опрошенных победителей Олимпиады Кружкового движения НТИ
                  прошлых лет поступили в&nbsp;вузы из&nbsp;первой десятки
                  в&nbsp;рейтингах лучших вузов страны: Университет ИТМО, МФТИ,
                  МГТУ им. Баумана, НИЯУ МИФИ
                </p>
              </div>
            </div>
            <div class="features__item">
              <div class="features__item-stage">
                <img
                  class="features__item-img"
                  src="@/assets/images/landing/features/target.png"
                  alt=""
                />
              </div>
              <div class="features__item-content">
                <h3 class="h3">Цель олимпиады</h3>
                <p>
                  Выявить молодых людей, готовых предложить новые ответы
                  на&nbsp;актуальные технологические вызовы
                </p>
              </div>
            </div>
          </div> -->
        </section>

        <section
          id="participants"
          class="section participants">
          <h2 class="participants__title h2">Треки олимпиады</h2>
          <div class="participants__row">
            <div class="participants__item">
              <div class="participant-card">
                <span class="participant-card__dots"></span>
                <div class="participant-card__inner">
                  <img
                    src="@/assets/images/landing/participants/icon_punched-card1.svg"
                    alt=""
                    class="participant-card__icon" />
                  <p class="participant-card__name">НТО Junior</p>
                  <h4 class="participant-card__title">Школьники 5−7 классов</h4>
                  <ul class="participant-card__list">
                    <li>— Первые шаги в&nbsp;мир технологий</li>
                    <li>— Опыт командной работы</li>
                    <li>— Подготовка к&nbsp;НТО школьников</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="participants__item">
              <div class="participant-card">
                <span class="participant-card__dots"></span>
                <div class="participant-card__inner">
                  <img
                    src="@/assets/images/landing/participants/icon_punched-card2.svg"
                    alt=""
                    class="participant-card__icon" />
                  <p class="participant-card__name">НТО школьников</p>
                  <h4 class="participant-card__title">
                    Школьники 8&minus;11 классов
                  </h4>
                  <ul class="participant-card__list">
                    <li>— Льготы при поступлении</li>
                    <li>— Знакомство с&nbsp;профессиями</li>
                    <li>— Портфолио достижений</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="participants__item">
              <div class="participant-card">
                <span class="participant-card__dots"></span>
                <div class="participant-card__inner">
                  <div class="participant-card__icon">
                    <img
                      src="@/assets/images/landing/participants/icon_punched-card3.svg"
                      alt=""
                      class="participant-card__icon" />
                  </div>
                  <p class="participant-card__name">НТО студентов</p>
                  <h4 class="participant-card__title">
                    Студенты вузов и&nbsp;колледжей
                  </h4>
                  <ul class="participant-card__list">
                    <li>— Реальные отраслевые кейсы</li>
                    <li>
                      &mdash;&nbsp;Приглашения на&nbsp;стажировки
                      и&nbsp;в&nbsp;магистратуру
                    </li>
                    <li>— Нетворкинг</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="participants__item">
              <div class="participant-cta">
                <div class="participant-cta__inner">
                  <h4 class="participant-card__title">
                    Стань участником и&nbsp;прояви&nbsp;себя в&nbsp;финале
                  </h4>
                  <div class="participant-cta__features">
                    <div class="participant-cta__feature">
                      Дипломы, призы и&nbsp;другие поощрения
                    </div>
                    <div class="participant-cta__feature">100 баллов ЕГЭ</div>
                    <div class="participant-cta__feature">
                      Практика и&nbsp;трудоустройство
                    </div>
                  </div>
                  <BaseButton
                    tag="a"
                    size="lg"
                    :href="regLink"
                    theme="primary"
                    :disabled="!isStarted"
                    block
                    @click="
                      gtmEvent({
                        eventLabel: 'contestant_block',
                        eventAction: 'go_to_registration',
                      })
                    "
                    >Зарегистрироваться</BaseButton
                  >
                  <div
                    v-if="!isStarted"
                    class="text-size-s mt-s">
                    Регистрация еще не началась
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- <section id="steps" class="section steps">
          <h2 class="h2 steps__title">Этапы олимпиады</h2>
          <div class="steps__row">
            <div class="steps__item step">
              <div class="steps__item-img">
                <img
                  src="@/assets/images/landing/steps/individual.png"
                  alt=""
                />
              </div>
              <div class="steps__item-head">
                <p class="steps__item-name">Отборочный</p>
                <h4 class="h4 steps__item-title">Индивидуальный</h4>
              </div>
              <p>
                Выполняй тестовые задания, используй несколько
                попыток&nbsp;&mdash; лучшая пойдет в&nbsp;зачет!
              </p>
            </div>
            <div class="steps__item">
              <div class="steps__item-img">
                <img src="@/assets/images/landing/steps/team.png" alt="" />
              </div>
              <div class="steps__item-head">
                <p class="steps__item-name">Отборочный</p>
                <h4 class="h4 steps__item-title">Командный</h4>
              </div>
              <p>
                Выполняй тестовые задания, используй несколько
                попыток&nbsp;&mdash; лучшая пойдет в&nbsp;зачет!
              </p>
            </div>
            <div class="steps__item">
              <div class="steps__item-img">
                <img src="@/assets/images/landing/steps/final.png" alt="" />
              </div>
              <div class="steps__item-head">
                <p class="steps__item-name">Командный</p>
                <h4 class="h4 steps__item-title">Финал</h4>
              </div>
              <p>
                Прояви свою свои сильные стороны на&nbsp;реальный кейсах
                реальных компаний в&nbsp;условиях сильной конкуренции
              </p>
            </div>
          </div>
        </section> -->

        <section
          id="preparation"
          class="section prep">
          <h2 class="h2 prep__title">Подготовка</h2>
          <div class="prep__row">
            <div class="prep__item">
              <h3 class="prep__item-title h4">Площадки подготовки</h3>
              <h4 class="prep__item-subtitle">
                Площадки подготовки к&nbsp;НТО есть во&nbsp;многих регионах
                России.
              </h4>
              <BaseButton
                tag="a"
                href="https://ntcontest.ru/study/area"
                size="lg"
                theme="reg"
                target="_blank"
                class="prep__item-btn"
                rel="noopener noreferrer"
                >Все площадки</BaseButton
              >
            </div>
            <div class="prep__item">
              <h3 class="prep__item-title h4">Материалы для&nbsp;подготовки</h3>
              <h4 class="prep__item-subtitle">
                Мы&nbsp;собрали в&nbsp;одном месте все материалы, которые
                помогут вам выступить отлично
              </h4>
              <BaseButton
                tag="a"
                href="https://ntcontest.ru/study/materials"
                size="lg"
                theme="reg"
                target="_blank"
                class="prep__item-btn"
                rel="noopener noreferrer"
                >Материалы</BaseButton
              >
            </div>
            <div class="prep__item">
              <h3 class="prep__item-title h4">Сборники прошлых лет</h3>
              <h4 class="prep__item-subtitle">
                На&nbsp;нашем сайте вы&nbsp;найдете сборники задач
                по&nbsp;каждому профилю за&nbsp;все годы.
              </h4>
              <BaseButton
                tag="a"
                href="https://ntcontest.ru/study/problembooks"
                size="lg"
                theme="reg"
                target="_blank"
                class="prep__item-btn"
                rel="noopener noreferrer"
                >Сборники</BaseButton
              >
            </div>
            <div class="prep__item">
              <h3 class="prep__item-title h4">
                Курсы подготовки<br />по&nbsp;технологиям НТИ
              </h3>
              <h4 class="prep__item-subtitle">
                Наши курсы для самых увлеченных участников.
              </h4>
              <BaseButton
                tag="a"
                href="https://ntcontest.ru/study/ntitechnologies"
                size="lg"
                theme="reg"
                target="_blank"
                class="prep__item-btn"
                rel="noopener noreferrer"
                >Курсы</BaseButton
              >
            </div>
          </div>
        </section>

        <section
          id="to-mentors-and-teachers"
          class="section mentors">
          <h2 class="mentors__title h2">Для наставников и&nbsp;учителей</h2>
          <div class="mentors__row">
            <img
              class="mentors__img"
              src="@/assets/images/landing/mentors/mentors.webp"
              alt=""
              width="578px"
              height="552px" />
            <div class="mentors__content">
              <div class="mentors__info">
                <h2 class="mentors__info-title h2">
                  Для наставников и&nbsp;учителей
                </h2>
                <p>
                  В&nbsp;Личном кабинете наставника вы&nbsp;можете приглашать
                  учеников и&nbsp;отправлять им&nbsp;уведомления с&nbsp;просьбой
                  связаться. Вы&nbsp;сможете следить за&nbsp;их&nbsp;прогрессом
                  и&nbsp;видеть, каким темам и&nbsp;заданиям стоит уделить
                  больше внимания.
                </p>
                <p>
                  Даже если у&nbsp;вас много учеников, наблюдать за&nbsp;ними
                  будет удобно.
                </p>
              </div>
              <div class="mentors__btns">
                <BaseButton
                  tag="a"
                  :href="regLink"
                  theme="primary"
                  size="lg"
                  class="mentors__btn"
                  @click="
                    gtmEvent({
                      eventAction: 'become_mentor',
                    })
                  "
                  >Стать наставником</BaseButton
                >
                <BaseButton
                  tag="a"
                  href="https://ntcontest.ru/mentors/"
                  theme="reg"
                  size="lg"
                  class="mentors__btn"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Для наставника</BaseButton
                >
              </div>
            </div>
          </div>
        </section>

        <section class="section extras">
          <h2 class="h2 extras__title">А что еще?</h2>
          <div class="extras__row">
            <div class="extras__item">
              <div class="extras__item-icon">
                <img
                  src="@/assets/images/landing/extras/icon_green.svg"
                  alt="" />
              </div>
              <h4 class="h4 extras__item-title">Приглашение наставника</h4>
              <p class="extras__item-text">
                Вы&nbsp;можете пригласить своего наставника зарегистрироваться,
                чтобы ему было проще следить за&nbsp;вашим прогрессом
                и&nbsp;поддерживать вас на&nbsp;каждом этапе соревнований.
              </p>
            </div>
            <div class="extras__item">
              <div class="extras__item-icon">
                <img
                  src="@/assets/images/landing/extras/icon_violet.svg"
                  alt="" />
              </div>
              <h4 class="h4 extras__item-title">
                Бонусы для участников прошлых лет
              </h4>
              <p class="extras__item-text">
                Финалисты трека для 8&minus;11 классов НТО&nbsp;&mdash;
                2023/2024 могут участвовать сразу во&nbsp;II отборочном этапе
                того&nbsp;же профиля, минуя&nbsp;I отборочный этап. Победителей
                и&nbsp;призеров прошлого сезона мы&nbsp;приглашаем в&nbsp;финал
                того профиля, где они заняли призовое место.
              </p>
            </div>
          </div>
        </section>
      </div>
    </main>

    <AppFooter class="reg-layout__footer">
      <template #links>
        <a
          href="https://talent.kruzhok.org/policy"
          target="_blank"
          rel="noopener noreferrer"
          >Политика обработки персональных данных</a
        >
        <a
          href="https://talent.kruzhok.org/terms_of_use"
          target="_blank"
          rel="noopener noreferrer"
          >Пользовательское соглашение</a
        >
      </template>
    </AppFooter>
  </div>
</template>

<script>
import store from "@/store";
import {
  PARTICIPANT_ROLE,
  MENTOR_ROLE,
  REGISTRATION_STAGE,
  PRE_REG_ENABLED_BEFORE,
} from "@/constants";
import AppFooter from "@/layouts/AppFooter";
import LandingModal from "@/modals/LandingModal";
import { mapGetters, mapState } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Hero from "@/components/landing/Hero.vue";
import ErrorModal from "@/modals/ErrorModal";
import BviButton from "@/components/BviButton.vue";
import { request } from "@/services/api";

const PRE_REG_EVENT = process.env.VUE_APP_PREREGISTRATION_EVENT_ID;

const SLIDER_OPTIONS = {
  observer: false,
  observeParents: false,
  loop: true,
  slidesPerView: "auto",
  loopedSlides: 5,
  spaceBetween: 10,
  speed: 5000,
  // allowTouchMove: false,
  autoplay: true,
};

export default {
  name: "LandingPage",
  components: { BviButton, Swiper, SwiperSlide, AppFooter, Hero },
  async beforeRouteEnter(to, from, next) {
    const user = store.state.user.user;
    const talentUser = store.state.user.talentUser;
    const nextRoute = to.query.next;
    let season;
    try {
      season = await store.dispatch("getSeason", { ignoreCache: true });
    } catch (error) {
      //
    }

    if (!season) {
      /**
       * Никуда не перенаправляем по умолчанию если олимпиада еще не началась
       */
      return next();
    }

    if (to.query.error) {
      return next();
    }

    // Пользователь авторизован в таланте и не зареган
    if (!user?.role && talentUser?.id) {
      next({
        name: "registration",
        query: to.query,
      });
      return;
    }

    if (user?.role === PARTICIPANT_ROLE) {
      if (user?.participant?.id && to.name !== "registration-profile") {
        next(
          nextRoute
            ? nextRoute
            : {
                name: "user-index",
              }
        );
        return;
      }
      if (!user?.participant?.id) {
        next({
          name: "registration",
          query: to.query,
        });
        return;
      }
    }

    if (user?.role === MENTOR_ROLE) {
      next({
        name: "mentor-index",
        query: to.query,
      });
      return;
    }

    next();
  },
  data() {
    return {
      showMenu: false,
      currentTime: Date.now(),
      startDate: null,
      timer: null,
      loading: true,
    };
  },
  metaInfo() {
    return {
      title: "Главная страница",
    };
  },
  computed: {
    ...mapState({
      season: "season",
    }),
    ...mapGetters({
      user: "user/user",
      isMentor: "user/isMentor",
      seasonStatus: "seasonStatus",
    }),
    swiperOptions() {
      return SLIDER_OPTIONS;
    },
    loginUrl() {
      if (this.seasonStatus !== "ended") {
        return `${this.$store.state.clientApiPrefix}/auth/go?next=/`;
      }
      return undefined;
    },
    regLink() {
      return `${this.$store.state.clientApiPrefix}/auth/go?next=/registration`;
    },
    isStarted() {
      return this.startDuration <= 0;
    },
    startDuration() {
      return this.startDate - this.currentTime;
    },
    registrationSchedule() {
      const schedule = this.$store.state.schedule || [];
      return schedule.filter((n) => n.stage === REGISTRATION_STAGE);
    },
    isAllRegClosed() {
      const { registrationSchedule } = this;
      const now = Date.now();
      if (!registrationSchedule.length) return true;
      return this.registrationSchedule.every((n) => {
        return n.end_at && now - new Date(n.end_at) > 0;
      });
    },
    preRegUrl() {
      if (!PRE_REG_EVENT) return;
      const url = `${process.env.VUE_APP_TALENT_BASE_URL}/registration?event=${PRE_REG_EVENT}`;
      if (this.startDuration > PRE_REG_ENABLED_BEFORE) {
        return url;
      }
      return url;
    },
  },
  mounted() {
    if (this.season) {
      this.$store
        .dispatch("getSchedule")
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    } else {
      this.getLastSeason();
    }
    const { error, status, msg, url } = this.$route.query;
    if (error === "participant_data") {
      this.showError({
        title: "Ошибка",
        message: decodeURIComponent(msg),
        status,
        url: decodeURIComponent(url),
        content: `Не удалось получить данные участника и перейти в личный кабинет. Пожалуйста, попробуйте еще раз или <a class="link" href="/support">сообщите нам</a> об этой ошибке.`,
      });
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    showError(data) {
      this.$modal.show(
        ErrorModal,
        {
          title: data.title,
          message: data.message,
          content: data.content,
          status: data.status,
          url: data.url,
          report: data.report,
        },
        {
          adaptive: true,
          scrollable: true,
          minHeight: 200,
          height: "auto",
        },
        {
          closed: () => {
            // eslint-disable-next-line no-unused-vars
            const { error, status, msg, url, ...rest } = this.$route.query;
            this.$router.push({ query: rest });
          },
        }
      );
    },
    handleCloseClick(e) {
      if (this.showMenu && !e.target.closest(".burger")) {
        this.showMenu = false;
        document.removeEventListener("click", this.handleCloseClick);
      }
    },
    handleBurger() {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        this.$nextTick(() => {
          document.addEventListener("click", this.handleCloseClick, true);
        });
      }
    },
    gtmEvent(params) {
      window.dataLayer?.push({
        event: "ntoLandingEvent",
        eventCategory: "nto_landing_cta",
        ...params,
      });
    },
    showModal() {
      this.$modal.show(
        LandingModal,
        {},
        {
          adaptive: true,
          classes: "no-padding",
          width: "90%",
          height: "auto",
        }
      );
    },
    async getLastSeason() {
      const { season } = this;
      // Если есть текщий сезолн, то ничего считать не нужно
      if (season) {
        this.loading = false;
        return;
      }
      try {
        const { data } = await request({
          method: "GET",
          url: "/last-season",
        });
        this.runTimer(data?.start_at);
      } catch (error) {
        // делаем ничего
      }
      this.loading = false;
    },

    runTimer(startAt) {
      if (!startAt) return;

      const time = new Date(startAt).getTime();
      const now = Date.now();
      this.startDate = time;

      if (now < time) {
        this.timer = setInterval(() => {
          this.currentTime = Date.now();
          if (this.currentTime > time) {
            clearInterval(this.timer);
          }
        }, 1000);
      }
    },
  },
};
</script>

<style lang="less">
.l-page {
  --grid-gutter-width: 40px;
  color: @white-blue;
  background-color: @black-menu;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  overflow: hidden;

  img {
    max-width: 100%;
  }

  .h1 {
    font-size: 80px;
    line-height: 0.8;
    font-weight: 600;
    margin-bottom: 0.75em;
  }

  .h2 {
    color: #8eb5ff;
    font-size: 60px;
    line-height: 0.9;
    font-weight: 600;
    margin-bottom: 0.75em;
  }

  .h3 {
    font-size: 40px;
    line-height: 1;
    font-weight: 600;
    margin-bottom: 0.75em;
  }

  .h4 {
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 0.75em;
  }

  .base-btn:focus {
    outline: #fff solid 1px;
  }

  @media (max-width: @screen-lg) {
    .h1 {
      font-size: 60px;
    }

    .h2 {
      font-size: 50px;
    }

    .h3 {
      font-size: 35px;
    }

    .h4 {
      font-size: 27px;
    }
  }

  @media (max-width: @screen-md) {
    .h1 {
      font-size: 45px;
    }

    .h2 {
      font-size: 35px;
    }

    .h3 {
      font-size: 30px;
    }

    .h4 {
      font-size: 25px;
    }
  }

  @media (max-width: @screen-xs) {
    padding-bottom: 44px;
  }

  @media (max-width: 400px) {
    .h1 {
      font-size: 35px;
    }

    .h2 {
      font-size: 30px;
    }

    .h3 {
      font-size: 25px;
    }

    .h4 {
      font-size: 22px;
    }
  }

  @media (max-width: @screen-xxs) {
    .h4 {
      font-size: 20px;
    }
  }
}
</style>

<style lang="less" scoped>
@section-offset: 60px;

.section {
  padding: @section-offset 0;
}

.about {
  padding-bottom: 90px;
  overflow: hidden;

  &__subtitle {
    max-width: 720px;
    margin-bottom: 2.2em;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 50px;
  }

  &__count {
    display: flex;
    margin-right: 45px;

    &-number {
      font-size: 240px;
      font-weight: 200;
      line-height: 180px;
      color: #017aff;
      letter-spacing: -0.1em;
      margin-bottom: 0;
      margin-right: 30px;
    }

    &-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        margin-bottom: 10px;
      }

      span {
        color: #017aff;
        font-weight: bold;
      }
    }
  }

  &__icons {
    flex-shrink: 0;
  }

  &__panel {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background-color: @dark-blue;

    p {
      font-size: 22px;
      margin-bottom: 0;
      margin-right: 35px;
    }

    .btn {
      flex-shrink: 0;
    }
  }

  @media (max-width: @screen-md-max) {
    &__panel {
      p {
        font-size: 19px;
        margin-right: 20px;
      }
    }
  }

  @media (max-width: @screen-xs-max) {
    padding-bottom: 60px;

    &__count {
      margin-right: 0;
    }

    &__row {
      flex-direction: column;
      align-items: center;
    }

    &__icons {
      img {
        max-width: none;
      }
    }

    &__panel {
      display: block;

      p {
        margin-bottom: 1em;
      }
    }
  }

  @media (max-width: @screen-xs) {
    &__panel {
      padding: 35px 20px;

      .btn {
        width: 100%;
      }
    }
  }

  @media (max-width: @screen-xxs) {
    &__count {
      &-number {
        font-size: 180px;
        margin-left: -0.1em;
      }

      img {
        display: block;
        max-width: 60px;
      }
    }
  }
}
.burger {
  display: block;
  position: relative;
  width: 34px;
  height: 24px;
  border: none;
  background: none;
  padding: 0;

  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    margin: auto;
    transition: transform 0.3s;
  }

  &__line,
  &::before,
  &::after {
    width: 100%;
    height: 2px;
    background-color: #fff;
  }

  &__line {
    transition: opacity 0.3s;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  &.active &__line {
    display: none;
  }

  &.active {
    &::before,
    &::after {
      top: 0;
      bottom: 0;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}
.extras {
  &__title {
    margin-bottom: 1.25em;
  }

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    flex-basis: 50%;

    &-icon {
      display: flex;
      width: 102px;
      height: 102px;
      align-items: center;
      margin-bottom: 30px;
    }
  }

  @media (max-width: @screen-sm-max) {
    &__row {
      display: block;
    }

    &__item {
      margin-bottom: 60px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.features {
  padding-top: 100px;
  padding-bottom: 20px;

  h3 {
    color: #8eb5ff;
    margin-bottom: 0.4em;
  }

  p {
    margin-bottom: 0;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }

    &-content {
      width: 40%;
      align-self: center;
    }

    &-stage {
      text-align: center;
      width: 40%;
    }
  }

  @media (max-width: @screen-xs-max) {
    padding-bottom: 60px;

    &__item {
      display: block;
      margin-bottom: 80px;

      &-content,
      &-stage {
        width: auto;
      }

      &-stage {
        margin-bottom: 40px;
      }
    }
  }
}
.features-slider {
  cursor: pointer;
  .swiper-wrapper {
    transition-timing-function: linear;
  }

  .swiper-slide {
    width: auto;
    min-width: 434px;
    margin-right: 10px;
  }

  &__slide {
    text-align: center;

    &-img {
      position: relative;
      padding-bottom: 58.52%;
      margin-bottom: 15px;

      &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        height: 8px;
        background-color: white;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        z-index: 1;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        user-select: none;
        pointer-events: none;
      }
    }

    p {
      display: inline-block;
      position: relative;
      padding-left: 25px;
      user-select: none;
      pointer-events: none;

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 13px;
        height: 13px;
        border: 3px solid white;
        border-radius: 50%;
      }
    }

    &:nth-child(5n + 1) &-img::before {
      background-color: @accent-blue;
    }

    &:nth-child(5n + 1) p::before {
      border-color: @accent-blue;
    }

    &:nth-child(5n + 2) &-img::before {
      background-color: #d200ff;
    }

    &:nth-child(5n + 2) p::before {
      border-color: #d200ff;
    }

    &:nth-child(5n + 3) &-img::before {
      background-color: #ff45aa;
    }

    &:nth-child(5n + 3) p::before {
      border-color: #ff45aa;
    }

    &:nth-child(5n + 4) &-img::before {
      background-color: #ff630d;
    }

    &:nth-child(5n + 4) p::before {
      border-color: #ff630d;
    }

    &:nth-child(5n + 5) &-img::before {
      background-color: #00cb51;
    }

    &:nth-child(5n + 5) p::before {
      border-color: #00cb51;
    }
  }

  @media (max-width: @screen-xs-max) {
    .swiper-slide {
      min-width: 340px;
    }
  }
}
.header {
  position: relative;
  padding: 65px 0;

  a {
    color: @white-blue;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    width: 200px;
    aspect-ratio: ~"300/105";
    height: auto;
    order: -1;

    &-img {
      width: 100%;
      height: 100%;
    }
  }

  &__nav {
    display: flex;
    justify-content: center;

    &-item {
      text-align: center;
      font-size: 14px;
      letter-spacing: 0.19em;
      font-weight: 600;
      text-decoration: none;
      text-transform: uppercase;
      margin-right: 40px;
      transition: color 0.3s;

      &:hover {
        color: #fff;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__burger {
    order: 2;
    display: none;
  }

  @media (max-width: @screen-lg) {
    &__nav {
      &-item {
        margin-right: 25px;
      }
    }
    &__logo {
      width: 110px;
    }
  }

  @media (max-width: @screen-md) {
    padding: 25px 0;
    background-color: @black-menu;
    z-index: 10;

    &__inner {
      position: relative;
    }

    &__logo {
      position: relative;
      width: 160px;
      margin: 0;
      z-index: 11;
    }

    &__burger {
      display: block;
    }

    &__nav {
      display: block;
      position: fixed;
      right: 100%;
      bottom: 100%;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      z-index: 10;
      background-color: @black-menu;
      text-align: center;
      padding: 20px 0;
      box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.5);

      &-item {
        &:nth-child(3) {
          margin-right: 0;
        }

        display: block;
        margin-right: 0;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      &.active {
        top: 80px;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        visibility: visible;
        opacity: 1;
        animation: fadein 0.3s;
        animation-direction: alternate;
        animation-fill-mode: both;
      }
    }
  }
}

.mentors {
  &__title {
    display: none;
  }

  &__row {
    display: flex;
    align-items: center;
  }

  &__img {
    order: 1;
    max-width: 50%;
    height: auto;
  }

  &__content {
    margin-right: 55px;
  }

  &__info {
    margin-bottom: 60px;

    p {
      margin-bottom: 1.5em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
  }

  &__btn {
    flex-grow: 1;
    margin: 10px;
  }

  @media (max-width: @screen-sm-max) {
    &__title {
      display: block;
    }

    &__row {
      display: block;
    }

    &__content {
      margin-right: 0;
    }

    &__info {
      &-title {
        display: none;
      }
    }

    &__img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      margin-bottom: 35px;
    }

    &__info {
      margin-bottom: 40px;
    }
  }
}
.participants {
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
    align-items: stretch;
  }

  &__item {
    width: 425px;
    min-height: 410px;
    padding: 20px;
    position: relative;

    &:nth-child(3) {
      margin-left: auto;
    }
  }

  @media (max-width: @screen-sm-max) {
    &__item {
      padding: 10px;
      width: 50%;
      min-height: 330px;
    }

    &__row {
      margin: -10px;
    }
  }

  @media (max-width: @screen-xs-max) {
    &__item {
      width: 100%;
      max-width: 500px;
      height: auto;
    }

    &__item:nth-child(even) {
      margin-left: auto;
    }
    &__item:nth-child(odd) {
      margin-left: 0;
    }
  }

  @media (max-width: @screen-xxs) {
    &__item {
      width: 100%;
      height: auto;
    }
  }
}

.participant-card {
  position: relative;
  min-height: 100%;

  &__title {
    font-size: 25px;
    margin-bottom: 0.6em;
  }

  &__dots {
    position: absolute;
    width: 6px;
    height: 20px;
    background-color: @black-menu;
    border-radius: 3px;
    bottom: 30px;
    left: 30px;
    z-index: 0;
    box-shadow: 70px 0px 0px 0px @black-menu, 97px 0px 0px 0px @black-menu,
      117px 0px 0px 0px @black-menu, 166px 0px 0px 0px @black-menu,
      189px 0px 0px 0px @black-menu, 214px 0px 0px 0px @black-menu,
      236px 0px 0px 0px @black-menu, 284px 0px 0px 0px @black-menu,
      332px 0px 0px 0px @black-menu, 355px 0px 0px 0px @black-menu;
  }

  &__inner {
    position: relative;
    z-index: 1;
    padding: 35px 30px;
    padding-bottom: 90px;
    min-height: 100%;

    &::after {
      position: absolute;
      width: 30px;
      right: 0;
      top: 153px;
      height: 14px;
      content: "";
      border-radius: 7px 0 0 7px;
      background-color: @black-menu;
      transform: translateY(18px);
    }
  }

  &::before {
    content: "";
    position: absolute;
    clip-path: polygon(0% 0%, 84% 0, 100% 20%, 100% 100%, 0% 100%);
    width: 100%;
    background-color: fade(#1c1c7a, 90%);
    height: 100%;
    border-radius: 4px;
  }

  &__icon {
    height: 52px;
    margin-bottom: 30px;
  }

  &__name {
    margin-bottom: 0.4em;
  }

  @media (max-width: @screen-sm-max) {
    &__inner {
      padding: 25px 30px 70px 30px;
    }

    &__icon {
      max-width: 120px;
      height: 42px;
    }

    &__title {
      font-size: 20px;
    }

    &__list {
      font-size: 16px;
    }
  }

  @media (max-width: @screen-xxs) {
    &__icon {
      max-width: 100px;
      margin-bottom: 20px;
    }
  }
}
.participant-cta {
  position: relative;
  // border: 1px solid #fff;
  border-radius: 5px;
  // clip-path: polygon(0 0, 83% 0, 100% 18.2%, 100% 100%, 0 100%, 0 0);
  background-color: #fff;
  position: relative;
  border-radius: 4px;
  min-height: 100%;
  clip-path: polygon(0% 0%, 84% 0, 100% 20%, 100% 100%, 0% 100%);

  .participant-card__title {
    padding-right: 23px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    background-color: @black-menu;
    display: block;
    border-radius: 4px;
    clip-path: polygon(0% 0%, 84% 0, 100% 20%, 100% 100%, 0% 100%);
  }

  &__inner {
    padding: 30px 40px;
    position: relative;
    z-index: 1;
  }

  &__features {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    align-items: self-start;
  }

  &__feature {
    position: relative;
    max-width: 90%;
    display: inline-flex;
    align-items: center;
    height: 48px;
    color: #fff;
    line-height: 16px;
    padding: 5px 35px;
    padding-left: 45px;
    border-radius: 30px;
    clip-path: polygon(0 0, 90% 0%, 100% 80%, 100% 100%, 0 100%, 0 45%);
    margin-bottom: 12px;
    flex-grow: 0;
    flex-shrink: 1;
    font-size: 16px;
    font-weight: bold;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 12px;
      bottom: 0;
      width: 20px;
      height: 20px;
      margin: auto;
      background-image: url("../assets/images/landing/participants/icon_star.svg");
    }

    &::after {
      position: absolute;
      content: "";
      width: 48px;
      height: 48px;
      background-color: #000;
      opacity: 0.5;
      border-radius: 50%;
      top: -11px;
      left: 100%;
      transform: translateX(-30px);
    }

    &:nth-child(1) {
      margin-left: auto;
      background-color: #0ec855;
    }

    &:nth-child(2) {
      background-color: #aa00ce;
    }

    &:nth-child(3) {
      margin-left: auto;
      background-color: #fe7224;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: @screen-sm-max) {
    &__inner {
      padding: 20px 25px;
    }
    &__features {
      margin-bottom: 18px;
    }

    &__feature {
      height: 38px;
    }
  }
}
.past-olympiad {
  .video-poster {
    max-width: 900px;
    background-image: url("../assets/images/landing/poster.jpg");
    background-size: cover;
    // background-color: fade(#000, 0.2);
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;

    &::before {
      content: "";
      display: block;
      padding-top: percentage(1080/1920);
    }
  }
}

.prep {
  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-basis: calc(50% - 40px);
    padding: 35px 40px;
    background-color: @dark-blue;
    margin: 20px;

    &-subtitle {
      margin-bottom: 1.5em;
    }

    &-btn {
      // padding: 0 65px;
      min-width: 200px;
    }
  }

  @media (max-width: @screen-sm-max) {
    &__row {
      display: block;
      margin: 0;
    }

    &__item {
      margin: 0;
      margin-bottom: 20px;
    }
  }

  @media (max-width: @screen-xs) {
    &__item {
      padding: 25px 20px;
      padding-bottom: 30px;

      &-btn {
        width: 100%;
        padding: 0 20px;
      }
    }
  }
}
.social {
  &__list {
    display: flex;
  }

  &__item {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    background-color: #0c0c28;
    border-radius: 50%;
    transition: background-color 0.3s;
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: lighten(#0c0c28, 10%);
    }
  }
}
.steps {
  counter-reset: step;

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    flex-basis: 30%;

    &-head {
      position: relative;
      padding-left: 85px;

      &::before {
        color: @black-menu;
        counter-increment: step;
        content: counter(step);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        font-weight: 600;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 62px;
        height: 62px;
        border-radius: 50%;
        background-color: #15d860;
      }
    }

    &:nth-child(2) &-head::before {
      background-color: #c237f2;
    }

    &:nth-child(3) &-head::before {
      background-color: @accent-blue;
    }

    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 205px;
      margin-bottom: 45px;

      img {
        height: auto;
      }
    }

    &-name {
      margin-bottom: 3px;
    }
  }

  @media (max-width: @screen-sm-max) {
    &__row {
      display: block;
    }

    &__item {
      margin-bottom: 80px;

      &:nth-child(2) {
        margin-top: -40px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.special-button {
  & ::v-deep .bvi-text {
    display: none;
  }

  @media (max-width: @screen-md) {
    order: 1;
    margin-left: auto;
    margin-right: 10px;
  }
}
</style>
